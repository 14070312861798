<template>
  <v-app>
    <loading :active.sync="is_call_api"></loading>
    <div style="background-color: rgb(243 246 249)">
      <h1 class="mt-5 mb-5" style="color: #3f4254">
        Danh sách topic vsr cho kỳ thi {{getLabelProductCategoryById()}}
      </h1>
      <p class="red--text" v-if="category_id == product_category_constant.PREPTALK_ENGLISH" >
        (Sử dụng để quản lý thứ tự hiển thị các topic trên giao diện APP/WEB của VSR PTE)
      </p>
    </div>

    <div class="pt-5 pl-5 pr-5">
      <v-btn
          rounded
          color="cyan"
          class="mb-5 ml-5"
          x-small
          large
          @click="openDialogSort()"
      >
        <v-icon color="white" dark>mdi-sort</v-icon
        ><span style="color: white">Sắp xếp thứ tự các topic</span>
      </v-btn>
      <div>
        <v-row>
          <v-col cols="12">
            <div class="table-responsive">
              <table
                  class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
              >
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Cover</th>
                  <th scope="col">Name</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(topic, i) in list_topic_vsr">
                  <tr :key="i">
                    <td scope="row">{{ i + 1 }}</td>
                    <td>
                      <v-img
                          v-if="topic.cover"
                          :src="topic.cover.vi"
                          max-width="100"
                          max-height="70"
                          class="rounded-lg"
                      ></v-img>
                    </td>
                    <td>
                      <p
                          class="text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ topic.name?.vi }} ( <span class="font-weight-bold">#{{topic.id}}</span> )
                      </p>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </v-col>
        </v-row>
      </div>
    </div>

    <v-row justify="center">
      <v-dialog v-model="dialogSort" scrollable persistent max-width="600px">
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Sắp xếp thứ tự topic</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogSort = false" right>mdi-close</v-icon>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <draggable :list="data_draggable">
                    <ul
                        class="list-unstyled drag-css"
                        v-for="(topic, i) in data_draggable"
                        :key="i"
                    >
                      <b-media tag="li">
                        <h5 class="mt-0 mb-1 cursor-pointer">
                          <span class="font-weight-bold">{{i+1}}.</span> {{ topic.name?.vi }}
                        </h5>
                      </b-media>
                    </ul>
                  </draggable>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="dialogSort = false">Close</v-btn>
            <v-btn color="blue darken-1" outlined text @click="sortTopic">Update</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";

import constantTestBuilder from "../tests/mixins/Support/constantTestBuilder";
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";

export default {
  name: "TopicVsrList",
  components: {
    draggable,
    Loading: () => import("vue-loading-overlay"),
  },
  mixins: [
    constantTestBuilder,
  ],
  data() {
    return {
      is_call_api: false,
      list_topic_vsr: [],
      dialogSort: false,
      data_draggable: [],
    }
  },
  computed: {
    category_id: function () {
      return this.$route.params.id;
    },
  },
  created() {
    this.getListCourseByCategoryId();
  },
  methods: {
    async getListCourseByCategoryId() {
      let categoryId = this.category_id;
      if (categoryId != this.product_category_constant.PREPTALK_ENGLISH) {
        this.list_topic_vsr = [];
        return;
      }
      let vm = this;
      vm.is_call_api = true;
      await ApiService.get("prep-app/courses/get-by-category-id/"+categoryId)
      .then(function (response) {
        vm.list_topic_vsr = response.data;
        vm.is_call_api = false;
      })
      .catch(function (error) {
        if (error) {
          vm.is_call_api = false;
        }
      })
    },
    getLabelProductCategoryById() {
      let obj = this.listLabelProductCategory().find(e => e.value == this.category_id);
      if (obj) return obj.label;
      return '';
    },
    openDialogSort() {
      this.data_draggable = JSON.parse(JSON.stringify(this.list_topic_vsr));
      this.dialogSort = true;
    },
    sortTopic() {
      let vm = this;
      Swal.fire({
        title: "Bạn có chắc chắn lưu lại thay đổi này ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Có",
        cancelButtonText: "Không",
      }).then((result) => {
        if (result.value) {
          let items = vm.data_draggable.map((item, index) => {
            return {
              category_course_id: item.product_category_course_id,
              offset: index + 1,
            };
          });
          let data = {
            data_sort: items,
          };
          vm.is_call_api = true;
          ApiService.post(
              "prep-app/product-category/" + this.category_id + "/course/sort",
              data
          )
              .then(function (res) {
                if (res.status === 200) {
                  vm.getListCourseByCategoryId();
                  vm.$toasted.success("Sắp xếp thành công !", {
                    theme: "toasted-primary",
                    position: "top-right",
                    duration: 4000,
                  });
                  vm.is_call_api = false;
                  vm.dialogSort = false;
                }
              })
              .catch(function (error) {
                vm.is_call_api = false;
                console.log(error);
              });
        }
      });
    },
  },
}
</script>

<style scoped>

</style>